import React from "react"
import { useQuery } from "urql"

import Container from "../components/Container"
import NewsCard from "../components/NewsCard"

const AllNewses = () => {
  const [{ data }] = useQuery({
    query: `
    query Posts{
      posts(orderBy: createdAt_DESC, first: 9999) {
        content
        id
        title
        banner {
          url
        }
      }
    }
  `,
  })

  return (
    <Container as="section" classes="all-newses">
      {data?.posts.map(node => (
        <NewsCard
          key={node.id}
          title={node.title}
          link={node.id}
          imageSrc={node.banner.url}
        />
      ))}
    </Container>
  )
}

export default AllNewses
