import * as React from "react"

// Sections
import BannerSection from "../../sections/BannerSection"
import AllNewses from "../../sections/AllNewses"
import Seo from "../../components/seo"

import szkola from "../../assets/images/szkola-2.jpg"

const NewsesPAge = () => {
  return (
    <>
      <BannerSection
        title="Najnowsze wydarzenia w naszej szkole"
        image={<img src={szkola} alt="szkoła" />}
      />
      <AllNewses />
    </>
  )
}

export const Head = () => (
  <Seo
    title="Aktualności"
    description="Aktualności w Szkole Podstawowej im. Emilii Sczanieckiej we Lwówku."
  />
)

export default NewsesPAge
